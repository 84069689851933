import React, {useEffect, useContext} from 'react';
import { UserContext } from "../context/userContext"
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import { observer } from 'mobx-react';
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import UStore from "../stores/user-store";
import { LogoutButton } from "./logoutButton";


const RenderLoginForm = observer( props => {

  const [data, setData] = React.useState({
    email: '',
    password: '',
    status: 'initial',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setData((current) => ({ ...current, status: 'loading' }));

    const paramsQ = {
        username: data.email,
        password: data.password
       }

    try {

      
      axios
      .post('/api/auth/login', paramsQ)
      .then((response) => {
        console.log(response.data);

        if (response.data.noUser) {
            setData((current) => ({ ...current, status: 'noUser' }));
        } else {
           if ( response.data.success ) 
            setData({ email: '', password: '', status: 'signed' }) 

        }
        
      })
      .catch((error) => {
        if (error.response)
        if (error.response.status === 401) {
            setData((current) => ({ ...current, status: 'noUser' }));
        } else {
            setData((current) => ({ ...current, status: 'failure' }));

        }
      })

    } catch (error) {
      setData((current) => ({ ...current, status: 'failure' }));
    }
  };

    return (
        <Card sx={{ 
            minWidth: 300,
            maxWidth: 600
         }}>
           {data.status !== 'signed' ? 
            <CardContent orientation="vertical">
                <form onSubmit={handleSubmit} id="login-form">

                        <Stack spacing={2}>
                        <FormLabel
                            sx={(theme) => ({
                                '--FormLabel-color': theme.vars.palette.primary.plainColor,
                            })}
                        >
                            Войти в учетную запись
                        </FormLabel>
                        <Input
                            id="usernamef"
                            sx={{ '--Input-decoratorChildHeight': '45px' }}
                            placeholder="Ваша@почта.тут"
                            type="email"
                            required
                            value={data.email}
                            onChange={(event) =>
                                setData((current) => ({ ...current, email: event.target.value, status: 'initial' }))
                            }
                            error={data.status === 'failure'}
                            
                               
                            
                        />
                        <Input
                            id="pasf"
                            sx={{ '--Input-decoratorChildHeight': '45px' }}
                            placeholder="Паролъ"
                            type="password"
                            required
                            value={data.password}
                            onChange={(event) =>
                                setData((current) => ({ ...current, password: event.target.value, status: 'initial' }))
                            }
                            error={data.status === 'failure'}
                            
                               
                            
                        />
                         <Button
                                    variant="solid"
                                    color="primary"
                                    loading={data.status === 'loading'}
                                    type="submit"
                                >
                                    Отправить
                         </Button>
                         </Stack>
                        {data.status === 'failure' && (
                            <FormHelperText
                                sx={(theme) => ({ color: theme.vars.palette.danger.plainColor })}
                            >
                                Ошибка. Попробуйте позже.
                            </FormHelperText>
                        )}
                        {data.status === 'noUser' && (
                            <FormHelperText
                                sx={(theme) => ({ color: theme.vars.palette.warning.plainColor })}
                            >
                                Пользователь не существует
                            </FormHelperText>
                        )}

                </form>
            </CardContent>
            :
            <CardContent orientation="vertical">
                <Typography color="success">Успешный вход</Typography>
            </CardContent>}
        </Card>
    );
})

const RenderPage = observer(() =>{
    const [userContext, setUserContext] = useContext(UserContext)
    return UStore.token !=null ? (
        <LogoutButton/>
      ) : (
        <RenderLoginForm/>
      )
        
      
})
const LoginConfirm = () => {
    

    const [userContext, setUserContext] = useContext(UserContext)
    const didLogRef = React.useRef(false); // триггер/заглушка чтоб не выполнять несколько раз код в useEffect в StrictMode - особенность React18
  
    const [queryParameters] = useSearchParams()
    let parm = queryParameters.get("token"); //получить токен из гет запроса 
    let navigate = useNavigate()
  
    useEffect(() => {

        if (didLogRef.current === false) { // проверка заглушки - далее выполнится 1 раз 
            didLogRef.current = true; // закрыть заглушку
            if (parm) { //TODO пройти логин в бэке с назначеным токеном
                axios.request({
                    headers: {
                        Authorization: `Bearer ${parm}`
                    },
                    method: "GET",
                    url: `/api/auth/verify`
                }).then(response => {
                   // console.log(response);
                    if (response.data.success) {

                        // setUserContext(oldValues => {
                        //     return { ...oldValues, token: response.data.token }
                        // })
                        UStore.updateToken(response.data.token)

                        //console.log(userContext);  
                    } else {
                        // setUserContext(oldValues => {
                        //     return { ...oldValues, token: null }
                        // })
                        UStore.updateToken(null)

                    }

                }).catch((error) => {
                    console.log(error);
                }).finally(
                    navigate("/login")
                )


            }
        }
        // componentWillUnmount
        return () => {
        }
    }, [parm, navigate]);
    
    // return userContext.token === null ? (
    //     <>Need to Login</>
    //   ) : userContext.token ? (
    //     <>Вы залогинены</>
    //   ) : (
    //     <>Загрузка</>
    //   )
    return
  }

export {RenderLoginForm, LoginConfirm, RenderPage}