import './App.css';
import '@fontsource/roboto';
import React, {useEffect, useContext, useCallback} from 'react';
import { observer } from 'mobx-react'; 
import menuStore from './stores/menu-store.js';
import AppBarUI from './ui/AppBarUI.js';
import {RenderLoginForm, LoginConfirm, RenderPage} from "./auth/login.js";
import { UserContext } from "./context/userContext.js"
import axios from 'axios'
import UStore from "./stores/user-store.js";


import Userfront, {
  LoginForm,
  PasswordResetForm 
} from "@userfront/toolkit/react"; 

import {
  BrowserRouter as Router,   
  Route,
  Routes,
  Link,
  useSearchParams
} from "react-router-dom";     

import CssBaseline from '@mui/joy/CssBaseline';
import Typography from '@mui/joy/Typography';

//import AppBarUI from './ui/AppBarUI.js';


//require('dotenv').config(); 

//Userfront.init('8b6jqmdb'); 

const drawerWidth = 240;       




const App = observer(() => {

  const [userContext, setUserContext] = useContext(UserContext)
  const didLogRef = React.useRef(false); 

  const verifyUser = useCallback(() => {

    
    axios
    .post('/api/auth/refreshToken', {}, {
      headers: { "Content-Type": "application/json" },
      credentials: "include"
    })
    .then((response) => {
      if (response.data.success) {
        // setUserContext(oldValues => {
        //         return { ...oldValues, token: response.data.token }
        //       })
        UStore.updateToken(response.data.token)      
      } else {
        // setUserContext(oldValues => {
        //   return { ...oldValues, token: null }
        // })
        UStore.updateToken(null)      
      }
      console.log('silent');

    })
    .catch((error) => {
     // console.log('error');
      if (error.response)
      if (error.response.status === 401) {
        UStore.updateToken(null)      

      //  clearTimeout(silentVerifyTimer)
      }
    })
  
    
    setTimeout(verifyUser, 5 * 60 * 1000)
    //   // call refreshToken every 5 minutes to renew the authentication token.

  }, [])


  useEffect(() => {

    if (didLogRef.current === false) { // проверка заглушки - далее выполнится 1 раз 
      didLogRef.current = true; // закрыть заглушку
      document.title = 'Smart & Жыр';   
      verifyUser() 
    }

  // componentWillUnmount
  return () => {

  }
  },[verifyUser])
 

  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const open = menuStore.show;
  const dark = menuStore.dark;

  // const theme = React.useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode: prefersDarkMode ? 'dark' : 'light',
  //       },
  //     }),
  //   [prefersDarkMode],
  // );
// <AppBarUI />
  return (
    <Router>
      <CssBaseline />
      <AppBarUI />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="dashboard/" element={<Dashboard />} />
        <Route path="login/" element={<RenderPage />} />
        <Route path="verify/" element={<LoginConfirm />} />

        <Route path="*" element={          
          <Typography >
            404
          </Typography>
        }/>

  
      </Routes>
    </Router>

  );
});

const Home = observer(() => {

  const didLogRef = React.useRef(false); // триггер/заглушка чтоб не выполнять несколько раз код в useEffect в StrictMode - особенность React18


  useEffect(() => {

    if (didLogRef.current === false) { // проверка заглушки - далее выполнится 1 раз 
      didLogRef.current = true; // закрыть заглушку
 
      

    }

    // componentWillUnmount
    return () => {
    }
  }, []);

  
  if (UStore.token != null) {
    axios.request({
      headers: {
          Authorization: `Bearer ${UStore.token}`
      },
      method: "GET",
      url: `/api/auth/me`
  }).then(response => {
    console.log('Details');
          UStore.updateDetails(JSON.stringify(response.data))
  
  }).catch((error) => {
      console.log(error);
      UStore.updateDetails(null)
  })
  }
  
  




  return (
    <div>
      {UStore.details}
    </div>
  );
})
 
function Login() {
  return (
    <div>
      <h2>Login</h2>
     
    </div>
  );
}

function test() {

  console.log('test-агтс');
  return 
}

const PasswordReset = () => {
  return (
    <div>
      <h2>Password Reset</h2>
     
    </div>
  );
}

const Dashboard = () => {
  
 // const userData = JSON.stringify(Userfront.user, null, 2);
  return (
    <div>
      <h2>Dashboard</h2>
      
      <button>Logout</button>
    </div>
  );
}

export default App;
