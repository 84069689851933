
import * as React from 'react';
import Grid from '@mui/joy/Grid';
import Avatar from '@mui/joy/Avatar';
import Typography from '@mui/joy/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/joy/IconButton';

import Drawer from '@mui/joy/Drawer';
import Sheet from '@mui/joy/Sheet';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Switch from '@mui/joy/Switch';


import menuStore from '../stores/menu-store.js';
import { observer } from 'mobx-react';




const AppBarUI = observer(props => {

    const [openMenu, setOpenMenu] = React.useState(false);

    const open = menuStore.show;

    const dark = menuStore.dark;

    const handleMenuClick = (event) => {
        setOpenMenu(true);
       }
       

    const renderMenu = () => {
        return (
            <Drawer
                size="md"
                variant="plain"
                anchor='right'
                open={openMenu}
                onClose={() => setOpenMenu(false)}
                slotProps={{
                    content: {
                        sx: {
                            bgcolor: 'transparent',
                            p: { md: 3, sm: 0 },
                            boxShadow: 'none',
                        },
                    },
                }}
            >
                <Sheet
                    sx={{
                        borderRadius: 'md',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <DialogContent sx={{ gap: 2, margin: '20px' }}>

                        <Stack spacing={2} direction="column">
                          <Typography sx={{  textAlign: 'center'}}>
                            test
                          </Typography> 
                        </Stack>

                    </DialogContent>

                    <Divider sx={{ mt: 'auto' }} />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        useFlexGap
                        spacing={1}
                    >
                        <Button
                            variant="outlined"
                            color="neutral"

                        >
                            Clear
                        </Button>
                        <Button onClick={() => setOpenMenu(false)}>Show 165 properties</Button>
                    </Stack>
                </Sheet>
            </Drawer>
        );
    }   

    const renderProfile = () => { 
        return(
            <></>
        )
    }
    return (

        <Grid container sx={{ flexGrow: 1 }}>

            <Grid xs="auto" container m={2}

                sx={{ flexGrow: 1 }}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs="auto" mr={2}>
                    <Avatar alt="Smart Jir" src="/static/images/smart_jir_small.png" size="lg" />
                </Grid>
                <Grid xs="auto" mr={2}>
                    <Typography
                        level="h4"
                        noWrap
                    >
                        Smart & Жыр ¿
                    </Typography>
                </Grid>

            </Grid>

            <Grid xs="auto" container m={2}

                sx={{ flexGrow: 1 }}
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center">
                <Grid xs="auto">
                    <IconButton disabled={false}
                        sx={{
                            "--IconButton-size": "48px"
                        }}
                        onClick={(e) => handleMenuClick(e)}>
                        <SettingsIcon fontSize="large" />
                    </IconButton>
                    {renderMenu()}
                </Grid>

            </Grid>

        </Grid>



    );
});

export default AppBarUI;
