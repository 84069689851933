import { makeAutoObservable } from 'mobx';


class MenuStore {
   show = true;
   dark = true;
   need2update = false;


  constructor() {
    makeAutoObservable(this);
  }

  updateShow(val) {
    this.show = val;
  }
  updateDark(val) {
    this.dark = val;
  }

  setNeed2Update(val){
    this.need2update = val;
  }

}

export default new MenuStore();
