import React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import axios from 'axios'
import UStore from "../stores/user-store";
import { observer } from 'mobx-react';

const handleExit = (tgt) => {

    axios.request({
        headers: {
            Authorization: `Bearer ${UStore.token}`
        },
        method: "GET",
        url: `/api/auth/logout`
    }).then(response => {
         //   UStore.updateDetails(null)
         //   UStore.updateToken(null)
         UStore.updateDetails(null)
         UStore.updateToken(null)
    
    }).catch((error) => {
        console.log(error);
       // UStore.updateDetails(null)
    })
}

const LogoutButton = observer(() => {

    return (
        <Stack spacing={2}>
            <Button
                variant="solid"
                color="primary"
                onClick={(e)=>handleExit(e.currentTarget)}
            >
                Выйти
            </Button>
        </Stack>
    )
})

export {LogoutButton}