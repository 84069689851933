import { runInAction, makeAutoObservable } from 'mobx';


class UserStore {
   token = null;
   details = null;
  

  constructor() {
    makeAutoObservable(this);
  }

  updateDetails = (val) => {

    runInAction(() => {
        this.details = val;
          })
    
  }
  updateToken = (val) => {
    
    runInAction(() => {
        this.token = val;
    })
  }

}

function createUserStore() {
    return new UserStore()
}

export default createUserStore() 
